import { render, staticRenderFns } from "./DatagridColumn.vue?vue&type=template&id=3d648338&scoped=true&"
import script from "./DatagridColumn.vue?vue&type=script&lang=ts&"
export * from "./DatagridColumn.vue?vue&type=script&lang=ts&"
import style0 from "./DatagridColumn.vue?vue&type=style&index=0&id=3d648338&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d648338",
  null
  
)


    import installDirectives from "!/Users/dhulsman/Projects/rentmagic.frontend/node_modules/bootstrap-vue-loader/lib/runtime/installDirectives.js"
    import {VBTooltip} from 'bootstrap-vue'
    installDirectives(component, {'b-tooltip': VBTooltip})
    

export default component.exports