var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
let DataGridCell = class DataGridCell extends Vue {
    constructor() {
        super(...arguments);
        this.cellValue = false;
    }
    get primaryKey() {
        return Object.values(this.columns).find((column) => column.IsPrimaryKey === true).Name;
    }
    get column() {
        return this.columns[this.name];
    }
    rowChanged(value) {
        return {
            newVal: value,
            prop: "Deleted",
            primaryKey: this.row[this.primaryKey].Value,
            description: "",
        };
    }
    cellValueChanged(newVal) {
        this.value.Value = newVal;
    }
    toggleDelete() {
        if (this.cellValue === true) {
            this.rowChanged(false);
            this.cellValue = false;
        }
        else {
            this.rowChanged(true);
            this.cellValue = true;
        }
    }
};
__decorate([
    Prop({ required: true })
], DataGridCell.prototype, "value", void 0);
__decorate([
    Prop({ required: true })
], DataGridCell.prototype, "name", void 0);
__decorate([
    Prop({ required: true })
], DataGridCell.prototype, "columns", void 0);
__decorate([
    Prop({ required: true })
], DataGridCell.prototype, "row", void 0);
__decorate([
    Emit()
], DataGridCell.prototype, "rowChanged", null);
__decorate([
    Watch("cellValue")
], DataGridCell.prototype, "cellValueChanged", null);
__decorate([
    Emit("valueChanged")
], DataGridCell.prototype, "toggleDelete", null);
DataGridCell = __decorate([
    Component
], DataGridCell);
export default DataGridCell;
