var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Watch, Emit, Prop } from "vue-property-decorator";
import { saveTable } from "@/services/datagrid";
import DatagridRow from "./datagrid/DatagridRow.vue";
import DatagridColumn from "./datagrid/DatagridColumn.vue";
let DataGrid = class DataGrid extends Vue {
    constructor() {
        super(...arguments);
        this.columns = this.selectedWindow.output.Data.Columns;
        this.rows = this.selectedWindow.output.Data
            .Rows;
        this.changedRowCount = 0;
    }
    get changedRows() {
        return this.$store.getters.selectedWindow.changedRows ?? [];
    }
    get selectedWindow() {
        return this.$store.getters.selectedWindow;
    }
    get primaryKey() {
        const primarykeyColumn = Object.values(this.columns).find((column) => column.IsPrimaryKey === true);
        return primarykeyColumn;
    }
    get selectedWindowType() {
        return this.$store.getters.selectedWindowType;
    }
    updateChangedRowsState(changedRows) {
        this.$store.state[this.selectedWindowType].changedRows = changedRows;
        this.changedRowCount = changedRows.length;
    }
    providedRowsChanged() {
        return this.rows;
    }
    async updateRow(rowChanges) {
        const rowIndex = this.rows.findIndex((row) => row[this.primaryKey.Name].Value === rowChanges[0].primaryKey);
        for (const rowChange of rowChanges) {
            this.rows[rowIndex][rowChange.prop] = rowChange.newCellData;
        }
    }
    async submit() {
        const rows = this.$store.state[this.selectedWindowType].changedRows;
        const filteredRows = [];
        for (const row of rows) {
            if (row.Criteria[this.primaryKey.Name].toString().startsWith("NEW")) {
                filteredRows.push({ Criteria: {}, Values: row.Values });
                continue;
            }
            filteredRows.push(row);
        }
        await saveTable({
            subject: this.selectedWindow.output.Request.Subject,
            data: filteredRows,
        });
        this.$store.state[this.selectedWindowType].changedRows = [];
        this.$store.state.loading = false;
        global.session.activeWindow.reset();
    }
    /** Fired when row is changed, update it in the VUE state */
    rowChanged(changedData) {
        const existingRowIndex = this.$store.getters.selectedWindow.changedRows.findIndex((changedRow) => changedRow.Criteria[this.primaryKey.Name] === changedData.primaryKey);
        // If row is deleted mark it on top level as Deleted
        let Delete = null;
        if (changedData.prop === "Deleted") {
            Delete = changedData.newVal;
            this.processDelete({ changedData });
        }
        if (existingRowIndex > -1) {
            this.$store.state[this.selectedWindowType].changedRows[existingRowIndex].Values[changedData.prop] = changedData.newVal;
            this.$store.state[this.selectedWindowType].changedRows[existingRowIndex].Delete = Delete;
            return;
        }
        const Criteria = {
            [this.primaryKey.Name]: changedData.primaryKey,
        };
        const Values = { [changedData.prop]: changedData.newVal };
        this.$store.state[this.selectedWindowType].changedRows.push({
            Criteria,
            Values,
            Delete,
        });
        this.changedRowCount = this.$store.state[this.selectedWindowType].changedRows.length;
    }
    processDelete({ changedData }) {
        const rowIndex = this.rows.findIndex((row) => row[this.primaryKey.Name].Value === changedData.primaryKey);
        this.$set(this.rows[rowIndex], "Deleted", { Value: changedData.newVal });
    }
    created() {
        this.$store.state[this.selectedWindowType].changedRows = [];
    }
};
__decorate([
    Prop({ required: true })
], DataGrid.prototype, "providedRows", void 0);
__decorate([
    Watch("changedRows")
], DataGrid.prototype, "updateChangedRowsState", null);
__decorate([
    Watch("rows"),
    Emit("rowsUpdated")
], DataGrid.prototype, "providedRowsChanged", null);
DataGrid = __decorate([
    Component({
        components: {
            DatagridRow,
            DatagridColumn,
        },
    })
], DataGrid);
export default DataGrid;
