var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Emit, Watch } from "vue-property-decorator";
import DatagridCell from "./DatagridCell.vue";
import DatagridCellDelete from "./datagridCellTypes/DatagridCellDelete.vue";
let DataGrid = class DataGrid extends Vue {
    get deleted() {
        return this.row.Deleted?.Value;
    }
    onRowChange(row) {
        this.datagridRow = row;
    }
    rowUpdated(data) {
        return data;
    }
    rowChanged(data) {
        return data;
    }
};
__decorate([
    Prop({ required: true })
], DataGrid.prototype, "columns", void 0);
__decorate([
    Prop({ required: true })
], DataGrid.prototype, "row", void 0);
__decorate([
    Watch("row", { immediate: true, deep: true })
], DataGrid.prototype, "onRowChange", null);
__decorate([
    Emit()
], DataGrid.prototype, "rowUpdated", null);
__decorate([
    Emit()
], DataGrid.prototype, "rowChanged", null);
DataGrid = __decorate([
    Component({
        components: { DatagridCell, DatagridCellDelete },
    })
], DataGrid);
export default DataGrid;
